<template>
  <div class="com-post-error">
    <PostHeader
      v-show="customHeader"
      :menu-status="false"
    >
      <template v-slot:left-btn>
        <slot name="header-left" />
      </template>
      <template v-slot:right-btn>
        <slot name="header-right" />
      </template>
      <slot name="header-title" />
    </PostHeader>

    <div class="com-post-error-main">
      <slot class="com-post-error-main">
        <div
          :class="`error-${status}`"
          class="com-post-error__img"
        />
        <div class="com-post-error__text">
          {{ text }}
        </div>
        <div
          v-if="buttonTitle"
          class="com-post-error__button"
        >
          <Button
            :title="buttonTitle"
            @click="$emit('buttonClick')"
            type="secondary"
            width="116px"
            size="l"
          />
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
import PostHeader from '@/component/post-header/index.vue';
import Button from '@/ui/button';

export default {
  name: 'ComPostError',
  components: {
    PostHeader,
    Button,
  },
  props: {
    customHeader: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '系统繁忙',
    },
    status: {
      type: String,
      validator: val => ['network', 'fail', 'empty'].indexOf(val) > -1,
      default: 'empty',
    },
    buttonTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
  computed: {
    //
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
